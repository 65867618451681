import { Col, DatePicker, Modal, Select, TreeSelect } from "antd"
import React, { useContext, useEffect, useState } from "react"
import calendar from "../../../assets/icons/actions/calendar.svg"
import { MenuContext } from "../../../context/menu"
import { Translate } from "../../../translate"
import { datetimeFormat, toDateLocalNow } from "../../../util/time"

import Title from "antd/es/typography/Title"
import { AuthContext } from "../../../context/auth"
import api from "../../../services/api"
import { messageError } from "../../../util/messages"

export const defaultStart = MenuContext.defaultStart
export const defaultStop = MenuContext.defaultStop

const MenuDashboard = (props) => {

    const { environment } = useContext(AuthContext)
    const { valueSelected, setValueSelected, data, setData, setLocation } = useContext(MenuContext)
    const { RangePicker } = DatePicker
    let { onClicked } = props
    const [locations, setLocations] = useState([])

    const onSelect = (value) => {
        setData(onClickHandler(value))
        setValueSelected(value)
    }

    const onSelectLocation = (value) => {
        if (value === undefined) {
            setLocation(undefined)
            return
        }
        setLocation(obterIdsDescendentes(value))
    }

    function obterIdsDescendentes(key) {
        // Função para buscar o nó alvo na árvore
        function encontrarNo(nos) {
            for (const no of nos) {
                if (no.key === key) return no;
                if (no.children && no.children.length > 0) {
                    const encontrado = encontrarNo(no.children);
                    if (encontrado) return encontrado;
                }
            }
            return null;
        }
        // Função para coletar todos os IDs descendentes
        function coletarIds(no) {
            let ids = [];
            if (no.children) {
                for (const filho of no.children) {
                    ids.push(filho.key);
                    ids = ids.concat(coletarIds(filho));
                }
            }
            return ids;
        }
        const local = encontrarNo(locations);
        const ids = local ? coletarIds(local) : []
        ids.push(local.key)
        return { local, ids };
    }

    function onClickHandler(_value) {
        let newData = {}
        switch (_value) {
            case "now":
                newData.start = toDateLocalNow().hour(0).minute(0).second(0)
                newData.end = toDateLocalNow().hour(23).minute(59).second(59)
                return newData
            case "yesterday":
                newData.start = toDateLocalNow().subtract(1, 'day').hour(0).minute(0).second(0)
                newData.end = toDateLocalNow().subtract(1, 'day').hour(23).minute(59).second(59)
                return newData
            case "7days":
                newData.start = toDateLocalNow().subtract(7, "day").hour(0).minute(0).second(0)
                newData.end = toDateLocalNow().hour(23).minute(59).second(59)
                return newData
            case "lastlmonth":
                newData.start = toDateLocalNow().subtract(1, "month").hour(0).minute(0).second(0)
                newData.end = toDateLocalNow().hour(23).minute(59).second(59)
                return newData
            case "all":
                newData.start = toDateLocalNow().subtract(10, "year").hour(0).minute(0).second(0)
                newData.end = toDateLocalNow().hour(23).minute(59).second(59)
                return newData
            default:
                setData(newData)
                setValueSelected(_value)
        }
    }

    let date = [
        {
            label: <Title level={5} type="secondary">{Translate.t("dashboard.today")}</Title>,
            value: "now",
            key: "now"
        },
        {
            label: <Title level={5} type="secondary">{Translate.t("dashboard.yesterday")}</Title>,
            value: "yesterday",
            key: "yesterday"
        },
        {
            label: <Title level={5} type="secondary"> {Translate.t("dashboard.seven_day")}</Title>,
            value: "7days",
            key: "7days"
        },
        {
            label: <Title level={5} type="secondary">{Translate.t("dashboard.last_month")}</Title>,
            value: "lastlmonth",
            key: "lastlmonth"
        },
        {
            label: <Title level={5} type="secondary">  {Translate.t("dashboard.all")}</Title>,
            value: "all",
            key: "all"
        }]


    useEffect(() => {
        if (onClicked) {
            onClicked(data)
        }
    }, [data])

    useEffect(() => {
        api.get("/maintenance/location/tree/")
            .then((response) => { setLocations(response.data) })
            .catch((error) => messageError(error))
    }, [environment])


    const selectDate = () => {
        Modal.warn({
            title: Translate.t("common.date.select"),
            content: (
                <div>
                    <RangePicker
                        onChange={(newValue) => {
                            setData({
                                start: newValue[0],
                                end: newValue[1]
                            })
                        }}
                        showTime={true}
                        format={datetimeFormat()} />
                </div>
            ),
            onOk() {
            },
        })
    }

    return (
        <>
            <Col id="menu" className="dashboard-select-container"  >
                <TreeSelect
                    treeDefaultExpandAll={true}
                    className="dashboard-select" style={{ width: "300px", marginRight: "10px" }}
                    placeholder={Translate.t("common.location.select")}
                    allowClear={true}
                    onClear={() => setLocation(undefined)}
                    treeData={locations}
                    onSelect={onSelectLocation}
                    showSearch={true}
                    filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                />
                <Select className="dashboard-select" style={{ width: "150px", marginRight: "10px" }}
                    defaultValue="now"
                    value={valueSelected === 'selection' ? "Data customizada" : valueSelected}
                    placeholder={Translate.t("common.rfid.select")}
                    options={date}
                    onSelect={onSelect}
                />
                <a
                    className={valueSelected === "selection" ? "selected" : ""}
                    onClick={() => {
                        onClickHandler("selection")
                        selectDate()
                    }}
                >

                    {/* {valueSelected === "selection" && data.start && data.end ?
                        <span style={{ fontSize: 14 }} className="no-mobile">  {dayjs(data?.start).format(datetimeFormat())}-{dayjs(data?.end).format(datetimeFormat())}</span>
                        : <span className="secondary-text-color" style={{ fontSize: 16 }}>{Translate.t("common.date.select")}</span>
                    } */}
                    <img style={{ marginLeft: "3px" }} src={calendar} alt="" title={Translate.t("common.date.select")}></img>
                </a>

            </Col>
        </>
    )
}

export default MenuDashboard
