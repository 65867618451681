import {
  DownOutlined,
  FileExcelOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Dropdown,
  Form,
  Progress,
  Row,
  Select,
  TreeSelect,
} from "antd";
import ExportJsonExcel from "js-export-excel";
import React, { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import searchIcon from "../../../assets/icons/actions/search.svg";
import trash from "../../../assets/icons/actions/trash.svg";
import { AuthContext } from "../../../context/auth";
import api from "../../../services/api";
import { Translate } from "../../../translate";
import { optionsEnum } from "../../../util/handleItens";
import { messageError } from "../../../util/messages";
import { datetimeFormat, toDateLocalNow } from "../../../util/time";

const Filter = ({ search, loading, data, range, setRange }) => {
  const { setSearchItemId } = useContext(AuthContext);
  const [form] = Form.useForm();
  const [locations, setLocations] = useState([]);
  const types = ["HOUR", "DAY", "MONTH", "WEEK", "YEAR"];
  const { RangePicker } = DatePicker;
  const [dataCsv, setDataCsv] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [exportPercent, setExportPercent] = useState(0);

  const handleValue = (values) => {
    if (values.range) {
      values.start = values.range[0].toISOString();
      values.end = values.range[1].toISOString();
      values.range = [];
    }
    if (values.locations) values.locations = values.locations.toString();
    if (values.types) values.types = values.types.toString();
    return values;
  };

  const handleGrades = (grade, grades) => {
    let item =
      grades && grades.length > 0 && grades.find((i) => i.name === grade);
    return item ? item.value : 0;
  };

  const handleSubmit = (values) => {
    if (!values.range || values.range.length < 2) {
      form.setFields([
        {
          name: "range",
          errors: [Translate.t("common.date.required")],
        },
      ]);
      return;
    } else {
      form.setFields([
        {
          name: "range",
          errors: [],
        },
      ]);
    }
    values = handleValue(values);
    search({
      ...values,
    });
  };

  const fetchlLocation = () => {
    api
      .get("/maintenance/location/tree/")
      .then((response) => {
        setLocations(response.data);
      })
      .catch((error) => messageError(error));
  };

  const exportToXls = async () => {
    try {
      setExportPercent(0);
      setExportLoading(true);
      var allData = data.map((element) => ({
        range: element.range,
        date: element.date,
        location: element.location + " - " + element.father,
        visits: element.entry ? element.entry : "",
        avaliation: element.avaliation ? element.avaliation : "",
        percentualAvaliation: element.percentualAvaliation
          ? element.percentualAvaliation
          : "",
        excellent: handleGrades("excellent", element.grades),
        good: handleGrades("good", element.grades),
        regular: handleGrades("regular", element.grades),
        bad: handleGrades("bad", element.grades),
      }));
      new ExportJsonExcel({
        fileName: `Reviews-${toDateLocalNow()}`,
        datas: [
          {
            sheetData: allData,
            sheetName: `Reviews`,
            sheetFilter: [
              "range",
              "date",
              "location",
              "visits",
              "avaliation",
              "percentualAvaliation",
              "excellent",
              "good",
              "regular",
              "bad",
            ],
            sheetHeader: [
              Translate.t("common.period.title"),
              Translate.t("aggregation.title"),
              Translate.t("location.title"),
              Translate.t("report.visits"),
              Translate.t("report.avaliation"),
              Translate.t("report.percentualAvaliation"),
              Translate.t("survey.grades.excellent"),
              Translate.t("survey.grades.good"),
              Translate.t("survey.grades.regular"),
              Translate.t("survey.grades.bad"),
            ],
            columnWidths: [10, 5, 30, 5, 5, 5, 5, 5, 5, 5],
          },
        ],
      }).saveExcel();
      setExportPercent(0);
    } finally {
      setExportLoading(false);
    }
  };

  const headers = [
    { label: Translate.t("common.period.title"), key: "range" },
    { label: Translate.t("aggregation.title"), key: "date" },
    { label: Translate.t("location.title"), key: "location" },
    { label: Translate.t("report.visits"), key: "visits" },
    { label: Translate.t("report.avaliation"), key: "avaliation" },
    {
      label: Translate.t("report.percentualAvaliation"),
      key: "percentualAvaliation",
    },
    { label: Translate.t("survey.grades.excellent"), key: "excellent" },
    { label: Translate.t("survey.grades.good"), key: "good" },
    { label: Translate.t("survey.grades.regular"), key: "regular" },
    { label: Translate.t("survey.grades.bad"), key: "bad" },
  ];

  const exportToCsv = async () => {
    try {
      setExportPercent(0);
      setExportLoading(true);
      var allData = data.map((element) => ({
        range: element.range,
        date: element.date,
        location: element.location + " - " + element.father,
        visits: element.entry ? element.entry : "",
        avaliation: element.avaliation ? element.avaliation : "",
        percentualAvaliation: element.percentualAvaliation
          ? element.percentualAvaliation
          : "",
        excellent: handleGrades("excellent", element.grades),
        good: handleGrades("good", element.grades),
        regular: handleGrades("regular", element.grades),
        bad: handleGrades("bad", element.grades),
      }));
      setExportLoading(false);
      setDataCsv(allData);
    } finally {
      setExportLoading(false);
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <span
          key="xlsx"
          title={Translate.t("action.xlsx")}
          onClick={exportToXls}
        >
          {Translate.t("action.xlsx")}
        </span>
      ),

      icon: <FileExcelOutlined />,
    },
    {
      key: "2",
      label: (
        <span key="csv" title={Translate.t("action.csv")} onClick={exportToCsv}>
          {Translate.t("action.csv")}
        </span>
      ),
      icon: <FileTextOutlined />,
    },
  ];

  useEffect(() => {
    if (dataCsv.length > 0 || exportLoading) {
      var downloadCsv = document.getElementById("downloadCsv");
      downloadCsv.click();
      setDataCsv([]);
    }
    setExportLoading(false);
  }, [dataCsv]);

  useEffect(() => {
    fetchlLocation();
  }, []);

  return (
    <Col xs={24}>
      <Collapse
        defaultActiveKey={["1"]}
        style={{ marginTop: "22px" }}
        className="card_accordion space-botton"
        bordered={false}
      >
        <Collapse.Panel
          className="reset"
          key="1"
          header={
            <span className="text-header primary-text-color">
              {Translate.t("title.search")}
            </span>
          }
        >
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            className="card_form"
          >
            <Row gutter={[8, 0]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  name="locations"
                  label={Translate.t("common.location.title")}
                >
                  <TreeSelect
                    allowClear
                    multiple
                    showSearch
                    placeholder={Translate.t("common.location.select")}
                    treeDefaultExpandAll
                    treeData={locations}
                    filterTreeNode={(search, item) => {
                      return (
                        item.title
                          .toLowerCase()
                          .indexOf(search.toLowerCase()) >= 0
                      );
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  name="range"
                  label={Translate.t("common.period.title")}
                  initialValue={range}
                >
                  <RangePicker
                    value={range}
                    onChange={(dates) => setRange(dates)}
                    showTime={true}
                    format={datetimeFormat()}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  name="aggregation"
                  label={Translate.t("aggregation.title")}
                >
                  <Select
                    allowClear
                    placeholder={Translate.t("aggregation.select")}
                    options={(optionsEnum(types) || []).map((i) => ({
                      ...i,
                      label: Translate.t(`aggregation.${i.value}`),
                    }))}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={16} lg={24}>
                <div className="btn-right">
                  <Row gutter={8}>
                    <Col>
                      <Button
                        icon={
                          <img
                            className="btn-img-icon"
                            src={trash}
                            alt="icon"
                          />
                        }
                        title={Translate.t("action.clear")}
                        onClick={() => {
                          setSearchItemId(undefined);
                          setRange([]);
                          form.resetFields();
                        }}
                      >
                        <span className="no-mobile">
                          {Translate.t("action.clear")}
                        </span>
                      </Button>
                    </Col>
                    <Col>
                      <Dropdown menu={{ items }}>
                        <Button icon={<DownOutlined />} loading={exportLoading}>
                          <span className="no-mobile export">
                            {exportLoading ? (
                              <Progress
                                size="small"
                                steps={5}
                                percent={exportPercent}
                              />
                            ) : (
                              Translate.t("action.export")
                            )}
                          </span>
                        </Button>
                      </Dropdown>
                    </Col>
                    <Col>
                      <Button
                        icon={
                          <img
                            className="btn-img-icon"
                            src={searchIcon}
                            alt="icon"
                          />
                        }
                        title={Translate.t("action.search")}
                        loading={loading}
                        htmlType="submit"
                        type="primary"
                      >
                        <span className="no-mobile">
                          {Translate.t("action.search")}
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>
      <CSVLink
        visible={false}
        id="downloadCsv"
        filename={`Reviews-${toDateLocalNow()}.csv`}
        data={dataCsv}
        headers={headers}
      ></CSVLink>
    </Col>
  );
};

export default Filter;
