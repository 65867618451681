import { Col, Empty, Popover, Row, Spin, Tabs } from "antd";

import React, { useContext, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Translate } from "../../translate";

import dayjs from "dayjs";
import bad from "../../assets/icons/actions/bad.svg";
import excellent from "../../assets/icons/actions/excellent.svg";
import good from "../../assets/icons/actions/good.svg";
import regular from "../../assets/icons/actions/regular.svg";
import { AuthContext } from "../../context/auth";
import { MenuContext } from "../../context/menu";
import api from "../../services/api";
import { messageError } from "../../util/messages";
import { number } from "../../util/number";

const Top4 = ({ start, end }) => {
  const { environment } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { location } = useContext(MenuContext)
  const [excellentSurvey, setExcellentSurvey] = useState([]);
  const [goodSurvey, setGoodSurvey] = useState([]);
  const [badSurvey, setBadSurvey] = useState([]);
  const [regularSurvey, setRegularSurvey] = useState([]);
  const vh = "45.5vh";

  function reduzirFrase(frase) {
    if (frase.length <= 20) {
      return frase;
    }
    const palavras = frase.split(" ");
    let fraseReduzida = "";
    for (const palavra of palavras) {
      if (palavra.length > 3) {
        fraseReduzida += palavra.substring(0, 3) + ".";
      } else {
        fraseReduzida += palavra + " ";
      }
      if (fraseReduzida.length >= 20) {
        break;
      }
    }
    fraseReduzida = fraseReduzida.trim();
    return fraseReduzida;
  }

  const fetchCountSurvey = () => {
    setLoading(true);
    try {
      api
        .get("/survey/survey/group/local/grade/date", {
          params: {
            start: start
              ? dayjs(start).toISOString()
              : dayjs().utc().hour(0).minute(0).second(0).toISOString(),
            end: end ? dayjs(end).toISOString() : dayjs().utc().toISOString(),
            locations: location && location.ids.length > 0 ? location.ids.join(",") : undefined,
          },
        })
        .then((response) => {
          handleData(response.data);
        })
        .catch((error) => {
          messageError(error);
        });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  function sortReviewsByGrade(reviews, gradeType) {
    return reviews
      .map(review => {
        const top = getHighestGrade(review.grades, gradeType);
        const order = parseInt((top.count / review.count) * 100);
        return { ...review, top, order };
      })
      .filter(review => review.top.count > 0)
  }

  function getHighestGrade(grades, gradeType) {
    return grades.find(grade => grade.grade === gradeType) || { grade: gradeType, count: 0 };
  }

  const handleData = (reviews = []) => {
    const excellent = sortReviewsByGrade(reviews.slice(), "excellent");
    const good = sortReviewsByGrade(reviews.slice(), "good");
    const regular = sortReviewsByGrade(reviews.slice(), "regular");
    const bad = sortReviewsByGrade(reviews.slice(), "bad");

    setExcellentSurvey(excellent);
    setGoodSurvey(good);
    setRegularSurvey(regular);
    setBadSurvey(bad);
  };

  function smile(value, count) {
    if (value.grade === "bad")
      return (
        <div>
          <img className="smile-img-top" src={bad} alt="" />
          <span className="smile-text bad">{number(Number((value.count / count) * 100), 1)} %</span>
        </div>
      );
    if (value.grade === "regular")
      return (
        <div>
          <img className="smile-img-top" src={regular} alt="" />
          <span className="smile-text regular">{number(Number((value.count / count) * 100), 1)} %</span>
        </div>
      );
    if (value.grade === "good")
      return (
        <div>
          <img className="smile-img-top" src={good} alt="" />
          <span className="smile-text good">{number(Number((value.count / count) * 100), 1)} %</span>
        </div>
      );
    if (value.grade === "excellent")
      return (
        <div>
          <img className="smile-img-top" src={excellent} alt="" />
          <span className="smile-text excellent">{number(Number((value.count / count) * 100), 1)} %</span>
        </div>
      );
  }

  function line(data) {
    const totalCount = data.count;
    const series = data?.grades?.map((grade) => grade.count) || [];
    const labels = data?.grades?.map((grade) => grade.grade) || [];
    const colors = {
      bad: "#FE4D43",
      regular: "#FDDD59",
      good: "#B5D855",
      excellent: "#79BF5C"
    };

    const chartOptions = {
      chart: { type: "pie" },
      labels: labels.map(label => Translate.t("survey.grades." + label)),
      colors: labels.map(label => colors[label]),
      legend: { position: "top" }
    };

    const content = (
      <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
        <Col><b>Local:</b> {`${data.local} < ${data.father}`}</Col>
        <Col><b>Total de avaliações:</b> {totalCount}</Col>
        <Col>
          <Chart options={{ ...chartOptions }} series={series} type="pie" width={300} />

        </Col>
      </Row>
    );


    return (
      <Popover content={content} trigger="hover" placement="top" style={{ cursor: "pointer" }}
        overlayStyle={{ width: "320px" }}>
        <Row className="card-top4" gutter={[8, 8]} style={{ padding: "10px", cursor: "pointer" }}>
          <Col xs={12} sm={4}>{smile(data.top, data.count)}</Col>
          <Col
            xs={12} sm={4}
          >
            <span className="secondary-text-color">
              {data.count}
            </span>
          </Col>
          <Col
            xs={12} sm={16}
            title={data.local}
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            <span className="secondary-text-color">
              {data.father ? `${data.local} < ${data.father}` : data.local}
            </span>
          </Col>
        </Row>
      </Popover>
    );
  }

  useEffect(() => {
    if (start && end) fetchCountSurvey();
  }, [start, end, environment, location]);

  const items = [

    {
      key: '1',
      label: <p className="secondary-text-color">{Translate.t("survey.grades.bad")}</p>,
      children: <Col style={{ minHeight: vh, maxHeight: vh, overflowY: "auto", overflowX: "hidden" }}>
        {badSurvey && badSurvey.length > 0 ? (
          badSurvey.sort((a, b) => b.order - a.order).map((i) => line(i))
        ) : (
          <Empty
            style={{ marginTop: "10%" }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </Col>,
    },
    {
      key: '2',
      label: <p className="secondary-text-color">{Translate.t("survey.grades.excellent")}</p>,
      children: <Col style={{ minHeight: vh, maxHeight: vh, overflowY: "auto", overflowX: "hidden" }}>
        {excellentSurvey && excellentSurvey.length > 0 ? (
          excellentSurvey.sort((a, b) => b.order - a.order).map((i) => line(i))
        ) : (
          <Empty
            style={{ marginTop: "10%" }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </Col>,
    },
    {
      key: '3',
      label: <p className="secondary-text-color">{Translate.t("survey.grades.good")}</p>,
      children: <Col style={{ minHeight: vh, maxHeight: vh, overflowY: "auto", overflowX: "hidden" }}>
        {goodSurvey && goodSurvey.length > 0 ? (
          goodSurvey.sort((a, b) => b.order - a.order).map((i) => line(i))
        ) : (
          <Empty
            style={{ marginTop: "10%" }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </Col>,
    },

    {
      key: '4',
      label: <p className="secondary-text-color">{Translate.t("survey.grades.regular")}</p>,
      children: <Col style={{ minHeight: vh, maxHeight: vh, overflowY: "auto", overflowX: "hidden" }}>
        {regularSurvey && regularSurvey.length > 0 ? (
          regularSurvey.sort((a, b) => b.order - a.order).map((i) => line(i))
        ) : (
          <Empty
            style={{ marginTop: "10%" }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </Col>,
    },
  ];

  return (
    <Row>
      <Col className="card_default" >
        <div className="div-dash-itens">
          <span className="text-dashboard secondary-text-color">
            {Translate.t("dashboard.top4")}
          </span>
          <Spin tip="Pesquisando" size="large" spinning={loading}>
            <Tabs defaultActiveKey="1" items={items} />
          </Spin>
        </div>
      </Col>
    </Row>
  );
};

export default Top4;
