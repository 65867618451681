import { DownOutlined } from "@ant-design/icons";
import { Checkbox, Col, Popover, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Translate } from "../../../translate";
import { getValueStorage, setValueStorage } from "../../../util/localStorange";

const List = ({ data, loading }) => {
  const [columnsVisives, setColumnsVisives] = useState([]);

  const handleGrades = (grade, grades) => {
    let item =
      grades && grades.length > 0 && grades.find((i) => i.name === grade);
    return item ? item.value : 0;
  };

  const columns = [
    {
      index: 0,
      title: Translate.t("common.period.title"),
      dataIndex: "range",
      key: "range",
      width: 250,
    },
    {
      index: 1,
      title: Translate.t("aggregation.title"),
      dataIndex: "date",
      key: "date",
      width: 150,
    },
    {
      index: 2,
      title: Translate.t("location.title"),
      dataIndex: "location",
      key: "location",
      width: 450,
      render: (text, record) => record.location + " - " + record.father,
    },
    {
      index: 3,
      title: Translate.t("report.visits"),
      dataIndex: "entry",
      key: "entry",
      render: (text, record) => record.entry || 0,
      width: 100
    },
    {
      index: 4,
      title: Translate.t("report.avaliation"),
      dataIndex: "avaliation",
      key: "avaliation",
      render: (text, record) => record.avaliation || 0,
      width: 100
    },
    {
      index: 5,
      title: Translate.t("report.percentualAvaliation"),
      dataIndex: "percentualAvaliation",
      key: "percentualAvaliation",
      render: (text, record) => record.percentualAvaliation || 0,
      width: 100
    },
    {
      index: 6,
      title: Translate.t("survey.grades.excellent"),
      dataIndex: "excellent",
      key: "excellent",
      render: (text, record) => handleGrades("excellent", record.grades),
      width: 100
    },
    {
      index: 7,
      title: Translate.t("survey.grades.good"),
      dataIndex: "good",
      key: "good",
      render: (text, record) => handleGrades("good", record.grades),
      width: 100
    },
    {
      index: 8,
      title: Translate.t("survey.grades.regular"),
      dataIndex: "regular",
      key: "regular",
      render: (text, record) => handleGrades("regular", record.grades),
      width: 100
    },
    {
      index: 9,
      title: Translate.t("survey.grades.bad"),
      dataIndex: "bad",
      key: "bad",
      render: (text, record) => handleGrades("bad", record.grades),
      width: 100
    },
  ];

  const handleHiddenColumns = (collIndex) => {
    if (columnsVisives.length > 0)
      return columnsVisives.find(
        (i, index) => index === collIndex && i.visible
      );
    return true;
  };

  const onChangeHiddenColumns = (index, checked) => {
    let _columns = (columnsVisives || []).map((i, _index) => ({
      ...i,
      visible: _index === index ? checked : i.visible,
    }));
    setValueStorage("columnsSurvey", JSON.stringify(_columns));
    setColumnsVisives(_columns);
  };

  useEffect(() => {
    let value = getValueStorage("columnsSurvey");
    if (value) setColumnsVisives(JSON.parse(value));
    else {
      let _columns = [
        { label: Translate.t("common.period.title"), key: "0", visible: true },
        { label: Translate.t("aggregation.title"), key: "1", visible: true },
        { label: Translate.t("location.title"), key: "2", visible: true },
        { label: Translate.t("report.visits"), key: "3", visible: true },
        { label: Translate.t("report.avaliation"), key: "4", visible: true },
        {
          label: Translate.t("report.percentualAvaliation"),
          key: "5",
          visible: true,
        },
        {
          label: Translate.t("survey.grades.excellent"),
          key: "6",
          visible: true,
        },
        { label: Translate.t("survey.grades.good"), key: "7", visible: true },
        {
          label: Translate.t("survey.grades.regular"),
          key: "8",
          visible: true,
        },
        { label: Translate.t("survey.grades.bad"), key: "9", visible: true },
      ];
      setValueStorage("columnsSurvey", JSON.stringify(_columns));
      setColumnsVisives(_columns);
    }
  }, []);

  return (
    <>
      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} className="card_default padding-list">
          <Row className="space-botton padding">
            <Col xs={12}>
              <span className="text-header primary-text-color">
                {Translate.t("title.search_result")}
              </span>
            </Col>
            <Col xs={12} className="alng-rigth">
              {columnsVisives.length > 0 && (
                <Popover
                  content={(columnsVisives || []).map((i, index) => (
                    <p value={i.label}>
                      <Checkbox
                        checked={i.visible}
                        onClick={(e) => {
                          onChangeHiddenColumns(index, e.target.checked);
                          e.stopPropagation();
                        }}
                      >
                        {" "}
                        <span className="secondary-text-color">{i.label}</span>
                      </Checkbox>
                    </p>
                  ))}
                  placement="bottom"
                  title={null}
                  trigger="hover"
                >
                  <span className="secondary-text-color">
                    {" "}
                    {Translate.t("common.selectInfo")}
                  </span>
                  <DownOutlined />
                </Popover>
              )}
            </Col>
          </Row>
          <Table
            scroll={{ y: 99999, x: 400 }}
            size="small"
            dataSource={data}
            columns={columns.filter((column) =>
              handleHiddenColumns(column.index)
            )}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
};

export default List;
