import { Col, Empty, Row, Spin } from "antd";
import React from "react";
import Chart from "react-apexcharts";

import data from "../data.json";
import dayjs from "dayjs";

const ChartItemRfid = ({ start, end, series, categories, loading }) => {

  const dataFormat = (date) => {
    if (!date || !start || !end) return '';
    const durationHours = end.diff(start, 'hour');
    const durationDays = end.diff(start, 'day');
    if (durationHours < 25) {
      return dayjs(date).format('HH:mm');
    } else if (durationDays < 31) {
      return dayjs(date).format('DD/MM/YYYY');
    } else if (durationDays < 365) {
      return dayjs(date).format('MM/YYYY');
    } else {
      return dayjs(date).format('YYYY');
    }
  };

  function formatTime(seconds) {
    if (seconds < 60) {
      return `${seconds} s`;
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes} m`;
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      return `${hours} h`;
    } else if (seconds < 2592000) {
      const days = Math.floor(seconds / 86400);
      return `${days} d`;
    } else {
      const months = Math.floor(seconds / 2592000);
      return `${months} mês${months !== 1 ? 'es' : ''}`;
    }
  }

  return (

    <Row>
      <Col xs={24} >
        {series ? (
          <Spin tip="Pesquisando " size="large" spinning={loading}>
            <Chart key={"aaaa"}
              options={{
                ...data.options,
                chart: {
                  toolbar: { show: true },
                },
                legend: {
                  show: true,
                  position: "bottom",
                  horizontalAlign: "center",
                  markers: {
                    width: 12,
                    height: 12,
                    radius: 12,
                  },
                  itemMargin: {
                    horizontal: 10,
                    vertical: 5
                  },
                  formatter: function (seriesName, opts) {
                    const total = opts.w.globals.seriesTotals[opts.seriesIndex];
                    return `${seriesName} (${formatTime(total)})`;
                  }
                },
                yaxis: {
                  labels: {
                    formatter: function (value) {
                      return formatTime(value);
                    }
                  },
                  // stepSize: 5
                },
                xaxis: {
                  type: "datetime",
                  labels: {
                    show: true,
                    formatter: function (value, timestamp) {
                      return dataFormat(new Date(timestamp));
                    },
                  },
                  categories: categories || [],
                },

                tooltip: {
                  x: {
                    format: {
                      formatter: function (value, timestamp) {
                        return dataFormat(new Date(timestamp));
                      },
                    },
                  },
                },

                dataLabels: {
                  enabled: true,
                  enabledOnSeries: undefined,
                  formatter: function (val, opts) {
                    return formatTime(val)
                  },
                  textAnchor: 'top',
                  distributed: false,
                  offsetX: 0,
                  offsetY: -5,
                  style: {
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 'bold',
                    colors: undefined
                  },
                  background: {
                    enabled: false,
                    foreColor: '#fff',
                    padding: 4,
                    borderRadius: 2,
                    borderWidth: 1,
                    borderColor: '#fff',
                    opacity: 0.9,
                    dropShadow: {
                      enabled: true,
                      top: 1,
                      left: 1,
                      blur: 1,
                      color: '#fff',
                      opacity: 0.45
                    }
                  },
                  dropShadow: {
                    enabled: false,
                    top: 1,
                    left: 1,
                    blur: 1,
                    color: '#000',
                    opacity: 0.45
                  }
                }
              }}
              series={series || []}
              type="area"
              height="300"
              width="100%"
            />

          </Spin>
        ) : (
          <Empty
            style={{ marginTop: "10%" }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </Col>
    </Row>
  );
};

export default ChartItemRfid;
