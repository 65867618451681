import { Col, Row, Tabs } from "antd";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/auth";
import { MenuContext } from "../../../context/menu";
import api from "../../../services/api";
import { Translate } from "../../../translate";
import { messageError } from "../../../util/messages";
import { toDateLocalChart } from "../../../util/time";
import ChartItemRfid from "./ChartItemRfid";
import ChartItemResolution from "./ChartItemResolution";
import ChartItemType from "./ChartItemType";

const ChartTab = ({ start, end }) => {
  const { environment } = useContext(AuthContext);
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const { location } = useContext(MenuContext)
  const [chartType, setChartType] = useState({
    key: "type",
    label: Translate.t("dashboard.maintenance_time")
  });


  const fetchMaintenances = async (arg) => {
    try {
      let { data } = await api.get(`/maintenance/maintenance/count/time/grouped/${arg}/date`, {
        params: {
          enabled: true,
          start: start
            ? dayjs(start).toISOString()
            : dayjs().utc().hour(0).minute(0).second(0).toISOString(),
          end: end ? dayjs(end).toISOString() : dayjs().utc().toISOString(),
          locations: location && location.ids.length > 0 ? location.ids.join(",") : undefined,
        },
      });
      return data || [];
    } catch (error) {
      messageError(error);
    }
  };

  const handleSeries = async () => {
    try {
      setLoading(true);
      let maintenances = await fetchMaintenances(chartType.key);
      let _dates = [];
      let _category = [];
      maintenances = maintenances.map((i) => ({ ...i, date: toDateLocalChart(i.date) }));
      maintenances.forEach((i) => {
        if (!_dates.includes(i.date)) _dates.push(i.date);
        if (!_category.includes(i.name)) _category.push(i.name);
      });
      setCategories(
        _dates.sort(function (a, b) {
          return new Date(b) - new Date(a);
        })
      );
      let _series = _category.map((c) => ({
        name: c,
        data: _dates.map(
          (_date) =>
            maintenances.find((i) => i.date === _date && i.name === c)
              ?.seconds || 0
        ),
      }))
      if (chartType.key === "type" || chartType.key === "resolutiontime")
        _series = _series.map((i) => ({
          ...i,
          name: Translate.t(`maintenance.${i.name}`),
        }));
      setSeries(
        _series
      );
    } catch (error) {
      console.error("Erro ao processar dados:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (start && end) handleSeries();
  }, [start, end, environment, location, chartType]);

  return (
    <Row className="card_default space-botton" style={{ minHeight: "22.5em" }}>
      <Col xs={24} className="div-dash-itens">
        <span className="text-dashboard secondary-text-color">
          {Translate.t("dashboard.maintenance_switch")}
        </span>
        <Tabs
          defaultActiveKey="type"
          onChange={(activeKey) => {
            if (activeKey === "type") {
              setChartType({
                key: "type",
                label: Translate.t("dashboard.maintenance_time"),
              });
            } else if (activeKey === "resolutiontime") {
              setChartType({
                key: "resolutiontime",
                label: Translate.t("dashboard.maintenance_time_resoloution"),
              });
            } else if (activeKey === "rfid") {
              setChartType({
                key: "rfid",
                label: Translate.t("dashboard.maintenance_time_rfid"),
              });
            }

          }}
          items={[
            {
              label: (
                <p className="secondary-text-color">
                  {Translate.t("dashboard.maintenance_time")}
                </p>
              ),
              key: "type",
              children: <ChartItemType start={start} end={end} loading={loading} series={series} categories={categories} />,
            },
            {
              label: (
                <p className="secondary-text-color">
                  {Translate.t("dashboard.maintenance_time_resoloution")}
                </p>
              ),
              key: "resolutiontime",
              children: <ChartItemResolution start={start} end={end} loading={loading} series={series} categories={categories} />,
            },
            {
              label: (
                <p className="secondary-text-color">
                  {Translate.t("dashboard.maintenance_time_rfid")}
                </p>
              ),
              key: "rfid",
              children: <ChartItemRfid start={start} end={end} loading={loading} series={series} categories={categories} />,
            },
          ]}
        />
      </Col>
    </Row>
  );
};

export default ChartTab;
