import { Col, Row } from "antd";
import React, { useContext, useState } from "react";
import AvgWc from "../../components/dashboard/AvgWc";
import Problems from "../../components/dashboard/chart/ChartProblems";
import Users from "../../components/dashboard/chart/ChartUsers";
import MenuDashboard from "../../components/dashboard/menu";
import Top4 from "../../components/dashboard/Top4";
import { MenuContext } from "../../context/menu";
import { Translate } from "../../translate";
import { date } from "../../util/time";
import Statistic from "../../components/dashboard/smile/Statistic";
import Category from "../../components/dashboard/smile/Category";
import ChartMaintenance from "../../components/dashboard/chart/ChartMaintenance";
import ChartTab from "../../components/dashboard/chart/ChartTab";


const Dashboard = () => {
  const { data, location } = useContext(MenuContext);
  const [start, setStart] = useState(data.start);
  const [end, setEnd] = useState(data.stop);

  const onClickedMenu = (data) => {
    let { start, end } = data;
    setStart(start);
    setEnd(end);
  };

  return (
    <Col xs={24}>
      <Row gutter={[8, 8]} justify={"space-between"}>
        <Col xs={24} className="space-botton">
          <span className="text-title primary-text-color">
            {Translate.t("menu.dashboard")}
          </span>
        </Col>
        <Col xs={24} sm={12}>
          <span className="text-sub primary-text-color">
            {location && location.local ? location.local.title : "Todos os locais"} | {date(start)} - {date(end)}
          </span>
        </Col>
        <Col
          xs={24}
          sm={12}
          className="alng-rigth space-botton">
          <MenuDashboard onClicked={onClickedMenu} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={16} className="space-botton">
          <Category start={start} end={end} />
        </Col>
        <Col xs={24} sm={8} className="space-botton">
          <Statistic start={start} end={end} />
        </Col>
        <Col xs={24} sm={16} >
          <Col xs={24} className="space-botton">
            <Users start={start} end={end} />
          </Col>
          <Col xs={24} className="space-botton">
            <Problems start={start} end={end} />
          </Col>
        </Col>
        <Col xs={24} sm={8} >
          <Col xs={24} className="space-botton">
            <AvgWc start={start} end={end} />
          </Col>
          <Col xs={24} className="space-botton">
            <Top4 start={start} end={end} />
          </Col>
        </Col>
        <Col xs={24}  >
          <Col xs={24} className="space-botton">
            <ChartTab start={start} end={end} />
          </Col>
        </Col>
      </Row>
    </Col>
  );
};

export default Dashboard;
