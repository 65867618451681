import { Col, Row, Spin, Tabs } from "antd";

import React, { useContext, useEffect, useState } from "react";
import { Translate } from "../../translate";

import dayjs from "dayjs";
import feedback from "../../assets/icons/actions/feedback.svg";
import percent from "../../assets/icons/actions/percent.svg";
import total_hours from "../../assets/icons/actions/total_hours.svg";
import users from "../../assets/icons/actions/users.svg";
import calendar from "../../assets/icons/actions/clock.svg";
import api from "../../services/api";
import { messageError } from "../../util/messages";
import { number } from "../../util/number";
import { AuthContext } from "../../context/auth";
import { MenuContext } from "../../context/menu";


const AvgWc = ({ start, end }) => {
  const { environment } = useContext(AuthContext);
  const [avgUsers, setAvgUsers] = useState();
  const [avgSurvey, setAvgSurvey] = useState();
  const [countHours, setCountHours] = useState();
  const [percentAvg, setPercentAvg] = useState();
  const [started, setStarted] = useState();
  const [canceled, setCanceled] = useState();
  const [finished, setFinished] = useState();
  const [pending, setPending] = useState();
  const [loading, setLoading] = useState(false);
  const { location } = useContext(MenuContext)

  const fetchAvgUsers = async () => {
    try {
      let { data } = await api.get("/control/event/avg/date", {
        params: {
          types: "entry",
          start: start
            ? dayjs(start).toISOString()
            : dayjs().utc().hour(0).minute(0).second(0).toISOString(),
          end: end ? dayjs(end).toISOString() : dayjs().utc().toISOString(),
          locations: location && location.ids.length > 0 ? location.ids.join(",") : undefined,
        },
      });
      return data;
    } catch (error) {
      messageError(error);
    }
  };

  const fetchAvgSurvey = async () => {
    try {
      let { data } = await api.get("/survey/survey/avg/date", {
        params: {
          start: start ? dayjs(start).toISOString() : undefined,
          end: end ? dayjs(end).toISOString() : undefined,
          locations: location && location.ids.length > 0 ? location.ids.join(",") : undefined,
        },
      });
      return data;
    } catch (error) {
      messageError(error);
    }
  };

  const fetchCountHours = async () => {
    try {
      let { data } = await api.get("/maintenance/maintenance/count/time/date", {
        params: {
          enabled: true,
          start: start ? dayjs(start).toISOString() : undefined,
          end: end ? dayjs(end).toISOString() : undefined,
          locations: location && location.ids.length > 0 ? location.ids.join(",") : undefined,
        },
      });
      return data;
    } catch (error) {
      messageError(error);
    }
  };

  const fetchMaitenanceStatus = async () => {
    try {
      let { data } = await api.get("/maintenance/maintenance/count/status", {
        params: {
          enabled: true,
          start: start ? dayjs(start).toISOString() : undefined,
          end: end ? dayjs(end).toISOString() : undefined,
          locations: location && location.ids.length > 0 ? location.ids.join(",") : undefined,
        },
      });
      return data;
    } catch (error) {
      messageError(error);
    }
  };

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        let avgUsers = await fetchAvgUsers();
        let avgSurvey = await fetchAvgSurvey();
        let countHours = await fetchCountHours();
        let maitenanceStatus = await fetchMaitenanceStatus();
        if (avgUsers && avgSurvey)
          setPercentAvg(
            number((avgSurvey.value / avgUsers.value) * 100, 1) + " %"
          );
        else setPercentAvg("0 %");
        setAvgUsers(avgUsers);
        setAvgSurvey(avgSurvey);
        setCountHours(countHours.seconds / 3600);
        setStarted(maitenanceStatus?.find(item => item.name === "started")?.count ?? 0);
        setCanceled(maitenanceStatus?.find(item => item.name === "canceled")?.count ?? 0);
        setFinished(maitenanceStatus?.find(item => item.name === "finished")?.count ?? 0);
        setPending(maitenanceStatus?.find(item => item.name === "pending")?.count ?? 0);

      } catch (error) {
        messageError(error);
      } finally {
        setLoading(false);
      }
    }
    if (start && end) {
      loadData();
    }
  }, [start, end, environment, location]);


  const items = [

    {
      key: '1',
      label: <p className="secondary-text-color">{Translate.t("dashboard.avgwc")}</p>,
      children: <Row xs={24} gutter={[8, 8]} style={{ marginTop: "1.5em" }}>
        <Col xs={4}>
          <img className="avg-img" src={users} alt="" />
        </Col>
        <Col xs={8}>
          <p className="secondary-text-color">
            Visitas por WC
          </p>
          <p className="secondary-text-color">
            {avgUsers ? Math.round(avgUsers.value) : 0}
          </p>
        </Col>
        <Col xs={4}>
          <img className="avg-img" src={feedback} alt="" />
        </Col>
        <Col xs={8}>
          <p className=" secondary-text-color">Feedback</p>
          <p className="secondary-text-color">
            {avgSurvey ? Math.round(avgSurvey.value) : 0}
          </p>
        </Col>
        <Col xs={4}>
          <img className="avg-img" src={percent} alt="" />
        </Col>
        <Col xs={8}>
          <p className="secondary-text-color">
            Percentagem média
          </p>
          <p className="secondary-text-color"> {percentAvg} </p>
        </Col>
        <Col xs={4}>
          <img className="avg-img" src={total_hours} alt="" />
        </Col>
        <Col xs={8}>
          <p className="secondary-text-color">
            Total de horas limpeza
          </p>
          <p className="secondary-text-color">
            {number(countHours, 1)} Hrs
          </p>
        </Col>
      </Row>,
    },
    {
      key: '2',
      label: <p className="secondary-text-color">{Translate.t("dashboard.maintenance_status")}</p>,
      children: <Row xs={24} gutter={[8, 8]} style={{ marginTop: "1.5em" }}>
        <Col xs={4}>
          <img className="avg-img" src={calendar} alt="" />
        </Col>
        <Col xs={8}>
          <p className="secondary-text-color">
            {Translate.t("maintenance.status.started")}
          </p>
          <p className="secondary-text-color">
            {started}
          </p>
        </Col>
        <Col xs={4}>
          <img className="avg-img" src={calendar} alt="" />
        </Col>
        <Col xs={8}>
          <p className="secondary-text-color">
            {Translate.t("maintenance.status.pending")}
          </p>
          <p className="secondary-text-color">
            {pending}
          </p>
        </Col>
        <Col xs={4}>
          <img className="avg-img" src={calendar} alt="" />
        </Col>
        <Col xs={8}>
          <p className="secondary-text-color">
            {Translate.t("maintenance.status.finished")}
          </p>
          <p className="secondary-text-color">
            {finished}
          </p>
        </Col>

        <Col xs={4}>
          <img className="avg-img" src={calendar} alt="" />
        </Col>
        <Col xs={8}>
          <p className="secondary-text-color">
            {Translate.t("maintenance.status.canceled")}
          </p>
          <p className="secondary-text-color">
            {canceled}
          </p>
        </Col>
      </Row>,
    },
  ];

  return (
    <Row>
      <Col className="card_default">
        <div className="div-dash-itens">
          <Spin tip="Pesquisando " size="large" spinning={loading}>
            <Tabs defaultActiveKey="1" items={items} />
          </Spin>
        </div>
      </Col>
    </Row>
  );
};

export default AvgWc;
