import { Col, Empty, Row, Spin } from "antd"
import dayjs from "dayjs"
import React, { useContext, useEffect, useState } from "react"
import Chart from "react-apexcharts"
import { AuthContext } from "../../../context/auth"
import api from "../../../services/api"
import { Translate } from "../../../translate"
import { messageError } from "../../../util/messages"
import { toDateLocalChart } from "../../../util/time"
import data from "../data.json"
import { MenuContext } from "../../../context/menu"
const Users = ({ start, end }) => {

    const { environment } = useContext(AuthContext)
    const [series, setSeries] = useState([])
    const [categorys, setCategorys] = useState([])
    const [loading, setLoading] = useState(false)
    const { location } = useContext(MenuContext)

    const fetchUsers = async () => {
        try {
            let { data } = await api.get("/control/event/group/count/date",
                {
                    params:
                    {
                        enabled: true,
                        types: "entry",
                        start: start ? dayjs(start).toISOString() : dayjs().utc().hour(0).minute(0).second(0).toISOString(),
                        end: end ? dayjs(end).toISOString() : dayjs().utc().toISOString(),
                        locations: location && location.ids.length > 0 ? location.ids.join(",") : undefined,
                    }
                })
            return data
        } catch (error) {
            messageError(error)
        }

    }

    const fetchSurveys = async () => {
        try {
            let { data } = await api.get("/survey/survey/group/count/date",
                {
                    params:
                    {
                        enabled: true,
                        start: start ? dayjs(start).toISOString() : undefined,
                        end: end ? dayjs(end).toISOString() : undefined,
                        locations: location && location.ids.length > 0 ? location.ids.join(",") : undefined,
                    }
                })
            return data
        } catch (error) {
            messageError(error)
        }
    }

    const handleSeries = async () => {
        try {
            setLoading(true)
            let users = await fetchUsers()
            let surveys = await fetchSurveys()
            let _dates = []
            users = users.map(i => ({ ...i, date: toDateLocalChart(i.date) }))
            surveys = surveys.map(i => ({ ...i, date: toDateLocalChart(i.date) }))
            users.forEach(i => { if (!_dates.includes(i.date)) _dates.push(i.date) })
            surveys.forEach(i => { if (!_dates.includes(i.date)) _dates.push(i.date) })
            setCategorys(_dates.sort(function (a, b) {
                return new Date(b) - new Date(a)
            })
            )
            setSeries([
                {
                    name: Translate.t("dashboard.users"),
                    data: _dates.length > 0 ? _dates.map(
                        (_date) => (users.find((i) => i.date === _date)?.count) || 0
                    ) : [],
                },
                {
                    name: Translate.t("dashboard.surveys"),
                    data: _dates.length > 0 ? _dates.map(
                        (_date) => surveys.find((i) => i.date === _date)?.count || 0
                    ) : [],
                },
            ])
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const dataFormat = (date) => {
        if (!date) return ''
        if (start && end && end.diff(start, 'hours') < 27) {
            return dayjs(date).format('HH:mm')
        }
        return dayjs(date).format('DD/MM/YYYY')
    }


    useEffect(() => {
        if (start && end)
            handleSeries()
    }, [start, end, environment, location])

    return (
        <>
            <Row className="card_default space-botton" style={{ minHeight: "20em" }}>
                <Col xs={24} className="div-dash-itens" >
                    {series ? <Spin tip="Pesquisando " size="large" spinning={loading} >
                        <span className="text-dashboard secondary-text-color">
                            {Translate.t("dashboard.chart_users")}
                        </span>
                        <Chart
                            title="Users"
                            className="chart"
                            options={{
                                ...data.options,
                                legend: {
                                    show: true,
                                    position: "bottom",
                                    horizontalAlign: "center",
                                    markers: {
                                        width: 12,
                                        height: 12,
                                        radius: 12,
                                    },
                                    itemMargin: {
                                        horizontal: 10,
                                        vertical: 5
                                    },
                                    formatter: function (seriesName, opts) {
                                        const total = opts.w.globals.seriesTotals[opts.seriesIndex];
                                        return `${seriesName} (${total})`;
                                    }
                                },
                                chart: {
                                    toolbar: { show: true },
                                },
                                stroke: {

                                    curve: ["linestep", "smooth"]
                                },

                                yaxis: series.length > 0 ? [
                                    {
                                        title: { text: Translate.t("dashboard.users") },
                                        opposite: false,
                                    },
                                    {
                                        title: { text: Translate.t("dashboard.surveys") },
                                        opposite: true,
                                    }
                                ] : [],
                                xaxis: {
                                    type: "datetime",
                                    labels: {
                                        show: true,
                                        formatter: function (value, timestamp) {
                                            return dataFormat(new Date(timestamp))
                                        }

                                    },
                                    categories: categorys || [],
                                },
                                tooltip: {
                                    x: {
                                        format: {
                                            formatter: function (value, timestamp) {
                                                return dataFormat(new Date(timestamp))
                                            }
                                        }
                                    }
                                },
                                dataLabels: {
                                    enabled: true,
                                    enabledOnSeries: undefined,
                                    formatter: function (val, opts) {
                                        return val
                                    },
                                    textAnchor: 'top',
                                    distributed: false,
                                    offsetX: 0,
                                    offsetY: -5,
                                    style: {
                                        fontSize: '14px',
                                        fontFamily: 'Helvetica, Arial, sans-serif',
                                        fontWeight: 'bold',
                                        colors: undefined
                                    },
                                    background: {
                                        enabled: false,
                                        foreColor: '#fff',
                                        padding: 4,
                                        borderRadius: 2,
                                        borderWidth: 1,
                                        borderColor: '#fff',
                                        opacity: 0.9,
                                        dropShadow: {
                                            enabled: true,
                                            top: 1,
                                            left: 1,
                                            blur: 1,
                                            color: '#fff',
                                            opacity: 0.45
                                        }
                                    },
                                    dropShadow: {
                                        enabled: false,
                                        top: 1,
                                        left: 1,
                                        blur: 1,
                                        color: '#000',
                                        opacity: 0.45
                                    }
                                }
                            }}
                            series={series || []}
                            type="line"
                            height="300"
                            width="100%"
                        />
                    </Spin> : <Empty style={{ marginTop: "10%" }} image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </Col>
            </Row>
        </>
    )
}

export default Users
