import { Button, Col, Row } from "antd"
import dayjs from "dayjs"
import React, { useContext, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import maintenance from "../../assets/icons/actions/maintenance.svg"
import Filter from "../../components/maintenance/Filter"
import List from "../../components/maintenance/List"
import { AuthContext } from "../../context/auth"
import api from "../../services/api"
import { Translate } from "../../translate"
import { messageError } from "../../util/messages"

const Maintenance = () => {

    const { environment, searchItemId } = useContext(AuthContext)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({})
    const [params, setParams] = useState({ page: 0, rowPerPage: 5, enabled: true, order: "desc" })
    const [categories, setCategories] = useState([])
    const [locations, setLocations] = useState([])
    const [status, setStatus] = useState([])
    const [rfids, setRfids] = useState([])
    const [open, setOpen] = useState(false)
    const [item, setItem] = useState()
    const [types, setTypes] = useState([])
    const [searchParams, setSearchParams] = useSearchParams();
    const [autoSelectedData, setAutoSelectedData] = useState()

    const fetchTypes = () => {
        api.get("/maintenance/pin/types")
            .then((response) => { setTypes(response.data) })
            .catch((error) => messageError(error))
    }

    const handleNew = () => {
        let date = dayjs()
        let histories = [
            { status: "started", timestamp: date },
        ]
        setOpen(true)
        setItem({ enabled: true, deleted: false, start: date, histories: histories },)
    }

    const fetchCategories = () => {
        api.get("/maintenance/category/tree/")
            .then((response) => { setCategories(response.data) })
            .catch((error) => messageError(error))
    }

    const fetchLocations = () => {
        api.get("/maintenance/location/tree/", { params: { disabled: true } })
            .then((response) => { setLocations(response.data) })
            .catch((error) => messageError(error))
    }

    const fetchlStatus = () => {
        api.get("/maintenance/maintenance/status")
            .then((response) => { setStatus(response.data) })
            .catch((error) => messageError(error))
    }

    const fetchlRfids = () => {
        api.get("/maintenance/pin/", { params: { page: 0, rowPerPage: 1000, enabled: true } })
            .then((response) => { setRfids(response.data.content) })
            .catch((error) => messageError(error))
    }

    const search = (values) => {
        setLoading(true)
        let _params = {
            ...params,
            ...values || {}
        }
        setParams(_params)
        api.get("/maintenance/maintenance/", { params: _params })
            .then((response) => {
                setData(response.data.content)
                setPagination({
                    current: response.data.number + 1,
                    pageSize: response.data.size,
                    total: response.data.totalElements,
                    showSizeChanger: true
                })
            })
            .catch((error) => {
                messageError(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const readParams = () => {
        let range = searchParams.get("range");
        let categories = searchParams.get("categories");
        let location = searchParams.get("location");
        range = range == undefined ? undefined : range.split("-").map((dateString) => {
            let date = dateString.split(" ");
            let day = date[0].split("/");
            let time = date[1].split(":");
            return new Date(
                parseInt(day[2]) + 2000,
                parseInt(day[1]) - 1,
                parseInt(day[0]),
                parseInt(time[0]),
                parseInt(time[1]),
                0
            );
        });

        let data = {
            range: range == undefined ? undefined : {
                start: range[0],
                end: range[1],
            },
            categories: categories == undefined ? undefined : categories.split(","),
            location: location == undefined ? undefined : location,
        }
        let autoSelectedData = Object.keys(data).reduce((obj, key) => {
            if (data[key] !== undefined) {
                obj[key] = data[key]
            }
            return obj
        }, {})
        setAutoSelectedData(Object.keys(autoSelectedData).length > 0 ? autoSelectedData : undefined)
    }

    useEffect(() => {
        fetchCategories()
        fetchLocations()
        fetchlStatus()
        fetchlRfids()
        fetchTypes()
        readParams()
    }, [environment])

    useEffect(() => {
        if (searchItemId)
            search({ id: searchItemId, page: 0, rowPerPage: 5, enabled: true, order: "desc" })
    }, [searchItemId])

    return (
        <>
            <Row>
                <Col xs={24} className="space-botton">
                    <span className="text-title primary-text-color"> {Translate.t("menu.maintenance")}</span>
                </Col>
                <Col xs={18} sm={16} md={14} lg={12} className="space-botton">
                    <span className="text-sub secondary-text-color">{Translate.t("title.info.maintenance")}</span>
                </Col>
                <Col xs={6} sm={8} md={10} lg={12} className="alng-rigth space-botton">
                    <Button type={"primary"} icon={<img className="btn-img-icon" src={maintenance} alt="maintenance" />} onClick={handleNew} title={Translate.t("action.new", { name: Translate.t("maintenance.title") })} >
                        <span className="no-mobile"> {Translate.t("action.new", { name: Translate.t("maintenance.title") })}</span>
                    </Button>
                </Col>
            </Row>
            <Filter
                search={search}
                autoSelectedData={autoSelectedData}
                loading={loading}
                categories={categories}
                locations={locations}
                status={status}
                rfids={rfids}
                types={types}
            />
            <List search={search}
                pagination={pagination}
                data={data}
                loading={loading}
                categories={categories}
                locations={locations}
                status={status}
                rfids={rfids}
                open={open}
                setOpen={setOpen}
                item={item}
                setItem={setItem}
                types={types}
            />
        </>
    )
}

export default Maintenance