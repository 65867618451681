import { Col, Empty, Row, Spin } from "antd";
import React, { useContext, useEffect, useState, useRef } from "react";
import { AuthContext } from "../../../context/auth";
import dayjs from "dayjs";
import { MenuContext } from "../../../context/menu";
import api from "../../../services/api";
import { messageError } from "../../../util/messages";
import { number } from "../../../util/number";
import "./style.css";
import { Translate } from "../../../translate";

const Category = ({ start, end }) => {
  const { environment, theme } = useContext(AuthContext);
  const { location } = useContext(MenuContext);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const imagesRef = useRef(new Map()); // Armazena imagens já buscadas
  const fetchIcon = async (id) => {
    if (imagesRef.current.has(id)) return; // Não busca se já existir
    try {
      const response = await api.get(`/maintenance/files/render/${id}`);
      if (response.data && response.data.content) {
        const imageSrc = `data:${response.data.contentType};base64,${response.data.content}`;
        imagesRef.current.set(id, imageSrc);
      }
    } catch (error) {
      messageError(error);
    }
  };

  const renderIcon = (cat) => {
    const src = imagesRef.current.get(cat.icon);
    return src ? (
      <img
        src={src}
        alt={cat.name}
        style={{
          width: "40px",
          height: "40px",
          objectFit: "contain",
          marginBottom: "8px",
          filter: theme == "light" ? "invert(1)" : "invert(0)",
        }}
      />
    ) : (
      <Spin />
    );
  };

  const fetchCountCategory = async () => {
    try {
      let { data } = await api.get("/survey/survey/group/count/category", {
        params: {
          start: start ? dayjs(start).toISOString() : undefined,
          end: end ? dayjs(end).toISOString() : undefined,
          locations: location && location.ids.length > 0 ? location.ids.join(",") : undefined,
        },
      });
      return data || [];
    } catch (error) {
      messageError(error);
      return [];
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        let data = await fetchCountCategory();
        setCategories(data);
        data.forEach(({ icon }) => fetchIcon(icon));
      } catch (error) {
        messageError(error);
      } finally {
        setLoading(false);
      }
    }
    if (start && end) fetchData();
  }, [start, end, environment, location]);

  const truncateName = (name) => {
    return name.length > 11 ? name.substring(0, 9) + "..." : name;
  };

  return (
    <Row style={{ overflow: "hidden" }}>
      <div style={{ width: "100%" }}>
        <Spin tip="Pesquisando" size="large" spinning={loading}>
          <Row gutter={[8, 8]} justify={categories.length > 0 ? "center" : "center"} align="middle">
            {categories.length > 0 ? categories.map((item, index) => (
              <Col title={item.name}
                xs={12}
                sm={8}
                md={6}
                lg={4}
                xl={3}
                key={index}
                className="card_category"
                style={{
                  padding: "5px",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px"
                }}
              >
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  {renderIcon(item)}
                  <span className="secondary-text-color" >
                    {number(item.total)}
                  </span>
                </div>
                <span className="secondary-text-color" >
                  {truncateName(item.name)}
                </span>
              </Col>
            )) : Translate.t("common.empty")}
          </Row>
        </Spin>
      </div>
    </Row>
  );
};

export default Category;
