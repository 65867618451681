import { Button, Col, Row } from "antd"
import React, { useContext, useEffect, useState } from "react"
import category from "../../assets/icons/actions/category.svg"
import Filter from "../../components/maintenance_category/Filter"
import List from "../../components/maintenance_category/List"
import { AuthContext } from "../../context/auth"
import api from "../../services/api"
import { Translate } from "../../translate"
import { messageError } from "../../util/messages"

const MaintenanceCategory = () => {

    const { environment, searchItemId } = useContext(AuthContext)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({})
    const [params, setParams] = useState({ page: 0, rowPerPage: 5, enabled: true, order: "desc" })
    const [open, setOpen] = useState(false)
    const [item, setItem] = useState()
    const [audiences, setAudiences] = useState([])
    const [types, setTypes] = useState([])

    const fetchAudience = () => {
        api.get("/maintenance/location/audiences")
            .then((response) => { setAudiences(response.data) })
            .catch((error) => messageError(error))
    }
    
    const fetchTypes = () => {
        api.get("/maintenance/category/type")
            .then((response) => { setTypes(response.data) })
            .catch((error) => messageError(error))
    }

    const handleNew = () => {
        setOpen(true)
        setItem({ enabled: true, deleted: false, itsPublic: true })
    }

    const search = (values) => {
        setLoading(true)
        let _params = {
            ...params,
            ...values || {}
        }
        setParams(_params)
        api.get("/maintenance/category/", { params: _params })
            .then((response) => {
                setData(response.data.content)
                setPagination({
                    current: response.data.number + 1,
                    pageSize: response.data.size,
                    total: response.data.totalElements,
                    showSizeChanger: true
                })
            })
            .catch((error) => {
                messageError(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }


    useEffect(() => {
        fetchAudience()
        fetchTypes()
    }, [environment])


    useEffect(() => {
        if (searchItemId)
            search({ id: searchItemId, page: 0, rowPerPage: 5, enabled: true, order: "desc" })
    }, [searchItemId])

    return (
        <>
            <Row>
                <Col xs={24} className="space-botton">
                    <span className="text-title primary-text-color"> {Translate.t("menu.category")}</span>
                </Col>
                <Col xs={18} sm={16} md={14} lg={12} className="space-botton">
                    <span className="text-sub secondary-text-color">{Translate.t("title.info.maintenance_category")}</span>
                </Col>
                <Col xs={6} sm={8} md={10} lg={12} className="alng-rigth space-botton">
                    <Button type={"primary"} icon={<img className="btn-img-icon" src={category} alt="category" />} onClick={handleNew} title={Translate.t("action.new", { name: Translate.t("common.category.title") })} >
                        <span className="no-mobile"> {Translate.t("action.new", { name: Translate.t("common.category.title") })}</span>
                    </Button>
                </Col>
            </Row>
            <Filter
                search={search}
                loading={loading}
                audiences={audiences}
                types={types}
            />
            <List
                search={search}
                pagination={pagination}
                data={data}
                loading={loading}
                open={open}
                setOpen={setOpen}
                item={item}
                setItem={setItem}
                audiences={audiences}
                types={types}
            />
        </>
    )
}

export default MaintenanceCategory