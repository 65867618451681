import dayjs from "dayjs"
import React, { createContext, useState } from "react"

export const defaultStart = dayjs().hour(0).minute(0).second(0)

export const defaultStop = dayjs().hour(23).minute(59).second(59)

export const MenuContext = createContext()

export const MenuProvider = ({ children }) => {

  const [valueSelected, setValueSelected] = useState("now")
  const [data, setData] = useState({ start: defaultStart, end: defaultStop })
  const [location, setLocation] = useState()

  return (
    <MenuContext.Provider
      value={{
        valueSelected: valueSelected,
        setValueSelected: setValueSelected,
        data: data,
        setData: setData,
        location: location,
        setLocation: setLocation,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
